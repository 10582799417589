import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {RouterOutlet} from '@angular/router';
import {AppService} from './app.service';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {ADMIN_FORM_DATE_FORMATS} from '@default-admin/admin-form/admin-form-date-formats';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet/>`,
  imports: [
    RouterOutlet,
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS, useValue: ADMIN_FORM_DATE_FORMATS
    }
  ]
})

export class AppComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private appService: AppService,
  ) {
    matIconRegistry.addSvgIcon('microsoft-teams', domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon-microsoft-teams.svg'));
  }

  ngOnInit(): void {
    if (this.document.location.hostname === 'localhost') {
      this.document.body.classList.add('develop');
      this.appService.environment = 'DEV';
    }
    if (this.document.location.hostname.includes('-t')) {
      this.document.body.classList.add('test');
      this.appService.environment = 'TEST';
    }
  }
}
