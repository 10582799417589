import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {throwError} from 'rxjs';
import {FlamingoMessageService} from './flamingo-message.service';

@Injectable()
export class FlamingoErrorHandler implements ErrorHandler {

  constructor(private injector: Injector,
              private ngZone: NgZone) {
  }

  handleError(error: any): void {
    const messageService = this.injector.get(FlamingoMessageService);

    if (error instanceof HttpErrorResponse) {
      if (error.status === 403) {
        this.ngZone.run(() => messageService.showAlert(
          {
            title: 'Operazione non autorizzata',
            description: 'Non sei autorizzato a eseguire questa azione. Contatta l\'amministratore per maggiori informazioni.',
            confirm: 'Ok',
          }
        ));
      } else {
        let errorMessage = error.error._embedded ? error.error._embedded.errors[0].message : error.error.message
          ? error.error.message : error.message
        this.ngZone.run(() => messageService.showAlert(
          {
            title: 'Errore',
            description: errorMessage,
            confirm: 'Ok',
          }
        ));
      }
    } else {
      console.error('Errore applicazione.', error);
      throwError(error);
    }
  }

}
